import { http } from '@/http-common'

import * as Response from '@/types/network/response'

import { UsersState as State, UserGroup, NodeWithAlias,
    UserWithEmail, UserGroupNodeInsert, UserGroupUserInsert,
    UserGroupNodeDelete, UserGroupUserDelete, UserGroupInsert } from '@/types/state'
import { Default as Commit } from '@/types/mutations'
import { AxiosResponse } from 'axios'

type UserGroupWithNode = { userGroupId: string, nodeWithAlias: NodeWithAlias }
type UserGroupWithUser = { userGroupId: string, userWithEmail: UserWithEmail }


export default {
    namespaced: true,
    state: {
        loaded: false,
        userGroups: [],
        allNodes: [],
        allUsers: [],
        isInPresentationMode: false
    },
    mutations: {
        SET_LOADED(state: State, loaded: boolean): void {
            state.loaded = loaded
        },
        SET_USER_GROUPS(state: State, userGroups: UserGroup[]): void {
            state.userGroups = userGroups
        },
        SET_ALL_NODES(state: State, nodes: NodeWithAlias[]): void {
            state.allNodes = nodes
        },
        SET_ALL_USERS(state: State, users: UserWithEmail[]): void {
            state.allUsers = users
        },
        ADD_USER_GROUP(state: State, userGroupInsert: UserGroupInsert): void {
            state.userGroups.push({
                id: userGroupInsert.userGroupId,
                name: userGroupInsert.name,
                fullAccess: userGroupInsert.fullAccess,
                nodes: [],
                users: []
            })
            state.userGroups.sort((g1, g2) => g1.name > g2.name ? 1 : -1)
        },
        DELETE_USER_GROUP(state: State, userGroupId: string): void {
            const groupIndex = state.userGroups.findIndex(ug => ug.id == userGroupId)
            if (groupIndex < 0) return

            state.userGroups.splice(groupIndex, 1)
        },
        ADD_NODE_TO_USER_GROUP(state: State, userGroupNodeInsert: UserGroupWithNode): void {
            const group = state.userGroups.find(ug => ug.id == userGroupNodeInsert.userGroupId)
            if (!group) return

            group.nodes.push(userGroupNodeInsert.nodeWithAlias)
            group.nodes.sort((n1, n2) => n1.alias > n2.alias ? 1 : -1)
        },
        ADD_USER_TO_USER_GROUP(state: State, userGroupUserInsert: UserGroupWithUser): void {
            const group = state.userGroups.find(ug => ug.id == userGroupUserInsert.userGroupId)
            if (!group) return

            group.users.push(userGroupUserInsert.userWithEmail)
            group.users.sort((u1, u2) => u1.email > u2.email ? 1 : -1)
        },
        REMOVE_NODES_FROM_USER_GROUP(state: State, userGroupNodeDelete: UserGroupNodeDelete): void {
            const group = state.userGroups.find(ug => ug.id == userGroupNodeDelete.userGroupId)
            if (!group) return

            userGroupNodeDelete.nodeIds.forEach(nodeId => {
                const i = group.nodes.findIndex(n => n.id == nodeId)
                if (i > -1)
                    group.nodes.splice(i, 1)
            });
        },
        REMOVE_USERS_FROM_USER_GROUP(state: State, userGroupUserDelete: UserGroupUserDelete): void {
            const group = state.userGroups.find(ug => ug.id == userGroupUserDelete.userGroupId)
            if (!group) return

            userGroupUserDelete.userIds.forEach(userId => {
                const i = group.users.findIndex(u => u.id == userId)
                if (i > -1)
                    group.users.splice(i, 1)
            });
        },
        SET_PRESENTATION_MODE(state: State, presentationMode: boolean): void {
            state.isInPresentationMode = presentationMode
        }
    },
    actions: {
        async init({ commit } : Commit): Promise<Response.AuthenticationFailed | boolean> {
            commit('SET_LOADED', false)
            return await http.get('/user-groups')
                .then((userGroups: any) => {
                    commit('SET_USER_GROUPS', userGroups?.data?.userGroups)
                    commit('SET_ALL_NODES', userGroups?.data?.allNodes)
                    commit('SET_ALL_USERS', userGroups?.data?.allUsers)
                    commit('SET_LOADED', true)
                })
                .catch((error : any) => {
                    return error
                })
        },
        async addUserGroup({ commit } : Commit, userGroupInsert: UserGroupInsert) {
            return await http.post('/user-groups', { Name: userGroupInsert.name, FullAccess: userGroupInsert.fullAccess })
            .then((result: any) => {
                commit('ADD_USER_GROUP', {
                    userGroupId: result.data, name: userGroupInsert.name, fullAccess: userGroupInsert.fullAccess
                })
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        },
        async deleteUserGroup({ commit } : Commit, userGroupId: string) {
            return await http.delete('/user-groups', { data: { UserGroupId: userGroupId } })
            .then((result: any) => {
                commit('DELETE_USER_GROUP', result.data)
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        },
        async addNodeToUserGroup({ commit } : Commit, userGroupNodeInsert: UserGroupNodeInsert) {
            return await http.post('/user-group-node-relation', {
                UserGroupId: userGroupNodeInsert.userGroupId, NodeId: userGroupNodeInsert.nodeId
            })
            .then((result: any) => {
                commit('ADD_NODE_TO_USER_GROUP', {
                    userGroupId: userGroupNodeInsert.userGroupId, nodeWithAlias: (result.data as NodeWithAlias)
                })
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        },
        async addUserToUserGroup({ commit } : Commit, userGroupUserInsert: UserGroupUserInsert) {
            return await http.post('/user-group-user-relation', {
                UserGroupId: userGroupUserInsert.userGroupId, UserId: userGroupUserInsert.userId
            })
            .then((result: any) => {
                commit('ADD_USER_TO_USER_GROUP', {
                    userGroupId: userGroupUserInsert.userGroupId, userWithEmail: (result.data as UserWithEmail)
                })
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        },
        async removeNodesFromUserGroup({ commit } : Commit, userGroupNodeDelete: UserGroupNodeDelete) {
            return await http.delete('/user-group-node-relation', {
                data: { UserGroupId: userGroupNodeDelete.userGroupId, NodeIds: userGroupNodeDelete.nodeIds }
            })
            .then((result: any) => {
                commit('REMOVE_NODES_FROM_USER_GROUP', result.data as UserGroupNodeDelete)
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        },
        async removeUsersFromUserGroup({ commit } : Commit, userGroupUserDelete: UserGroupUserDelete) {
            return await http.delete('/user-group-user-relation', {
                data: { UserGroupId: userGroupUserDelete.userGroupId, UserIds: userGroupUserDelete.userIds }
            })
            .then((result: any) => {
                commit('REMOVE_USERS_FROM_USER_GROUP', result.data as UserGroupUserDelete)
                return { err: null }
            })
            .catch((error: any) => {
                return { err: error }
            })
        }
    },
    getters: {
        Loaded: (state: State) : boolean => state.loaded,
        UserGroups: (state: State) : UserGroup[] => !state.isInPresentationMode
            ? state.userGroups
            : state.userGroups.map(userGroup => (
                {
                    id: userGroup.id,
                    name: "Gebruikersgroep",
                    fullAccess: userGroup.fullAccess,
                    nodes: userGroup.nodes.map(node => ({id: node.id, alias: "EMRA"})),
                    users: userGroup.users.map(user => ({id: user.id, email: "Anoniem"}))
                })
            ),
        AllNodes: (state: State) : NodeWithAlias[] => !state.isInPresentationMode
            ? state.allNodes
            : state.allNodes.map(nodeWithAlias => ({id: nodeWithAlias.id, alias: "EMRA"})),
        AllUsers: (state: State) : UserWithEmail[] => !state.isInPresentationMode
            ? state.allUsers
            : state.allUsers.map(userWithEmail => ({id: userWithEmail.id, email: "Anoniem"})),
        IsInPresentationMode: (state: State) : boolean => state.isInPresentationMode
    }
}

