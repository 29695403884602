export enum UserRole {
    Admin = 'Admin',
    User = 'User',
    Moderator = 'Moderator',
    None = ''
}

export enum AuthenticationStatus {
    LoggedOut = 0,
    LoggedIn = 1,
    Success = 2,
    Failed = 3
}