import { http } from '@/http-common'

import { Default as Actions } from '@/types/mutations'
import * as CRUD from '@/types/CRUD'
import * as Response from '@/types/network/response'
import { AxiosResponse } from 'axios'

const findIndexById = (array: Array<CRUD.IdentifiedEntity>, element: CRUD.IdentifiedEntity) => array.findIndex(_ => _.Id == element.Id)

export default {
    namespaced: true,
    state: {
        entityTypes: [],
        map: new Map(),
        fkMap: new Map(),
        updated: 0
    },
    mutations: {
        ADD_ENTITIES(state: CRUD.State, request: CRUD.BulkMapMutation) {
            const { Model, EntityArray } = request
            const array = state.map.get(Model)
            const modelAttributes = state.entityTypes.find(_ => _.Name == Model )?.Attributes

            const foreignKeys = modelAttributes
                ?.filter((attr : any) => attr.IsForeignKey)
                ?.map((attr : any) => attr.Name);
            
            EntityArray?.map((entity : any) => {
                foreignKeys?.forEach((key: any) => {
                    if (entity[key])
                        entity["display" + key] = entity[key]["Display"]
                })

                return entity
            })

            if (array && Array.isArray(array)) {
                array.splice(0, array.length, ...EntityArray)
                state.updated++
            }
            
        },
        ADD_ENTITY(state: CRUD.State, request: CRUD.MapMutation) {
            const { Model, Entity } = request
            const array = state.map.get(Model)

            // Add the custom display field for all foreign keys
            state.entityTypes.find(_ => _.Name == Model )
                ?.Attributes
                ?.filter((attr : any) => attr.IsForeignKey)
                ?.map((attr : any) => attr.Name)
                ?.forEach((key : any) => {
                    Entity["display" + key] = Entity[key]["Display"]
                })

            if (array != undefined) {
                array.push(Entity)
                state.updated++
            }
        },
        UPDATE_ENTITY(state: CRUD.State, request: CRUD.MapMutation) {
            const { Model, Entity } = request
            const array = state.map.get(Model)
            
            if (array != undefined) {
                array[findIndexById(array, Entity)] = Entity
                state.updated++
            }
        },
        DELETE_ENTITY(state: CRUD.State, request: CRUD.MapMutation) {
            const { Model, Entity } = request
            const array = state.map.get(Model)
            
            if (array != undefined) {
                array.splice(findIndexById(array, Entity), 1)
                state.updated++
            }
        },
        RESET(state: CRUD.State, _entityTypes: Array<CRUD.Entity>) {
            _entityTypes.forEach(_ => {
                state.map.set(_.Name, [])
                state.fkMap.set(_.Name, _.Attributes
                    .filter((attr : any) => attr.IsForeignKey)
                    .map((attr : any) => attr.NativeType))
            })
            state.entityTypes = _entityTypes
        }
    },
    actions: {
        async Init({ commit } : Actions) {
            return await http.get('/meta/models')
                .then((response : AxiosResponse) => {
                    commit('RESET', response.data)
                    return null
                })
                .catch(_ => _.response)
        },
        async Create({ commit } : Actions, mutation: CRUD.MapMutation) {
            const request : CRUD.CreateRequest = {
                Model: mutation.Model,
                Entity: JSON.stringify(mutation.Entity)
            }
            return await http.post('/admin/model', request)
                .then(response => {
                    mutation.Entity = response.data
                    commit('ADD_ENTITY', mutation)

                    return { err: null, entity: response.data }
                })
                .catch(_ => {
                    return { err: _.response }
                })
        },
        async Read({ commit } : Actions, request: CRUD.ReadRequest) {
            if (!request.Model)
                return
            else
                return await http.get(`/admin/model?model=${ request.Model }`)
                    .then(response => {
                        const bulkMapMutation = { Model: request.Model, EntityArray: response.data }
                        commit('ADD_ENTITIES', bulkMapMutation)
                        return null
                    })
                    .catch(_ => _.response)
            
        },
        async Update({ commit } : Actions, mutation: CRUD.MapMutation) {
            const request : CRUD.UpdateRequest = {
                Model: mutation.Model,
                Entity: JSON.stringify(mutation.Entity)
            }

            return await http.put('/admin/model', request)
                .then(_ => {
                    commit('UPDATE_ENTITY', mutation)

                    return null
                })
                .catch((error : Response.AuthenticationError) => {
                    return error.response
                })
        },
        async Delete({ commit } : Actions, mutation: CRUD.MapMutation) {
            const request : CRUD.DeleteRequest = {
                Model: mutation.Model,
                Entity: JSON.stringify(mutation.Entity)
            }

            return await http.delete(`/admin/model?model=${request.Model}&Entity=${request.Entity}`)
                .then(_ => {
                    commit('DELETE_ENTITY', mutation)
                    return null
                })
                .catch(_ => _.response)
        }
    },
    getters: {
        models: (state: CRUD.State): any[] => state.entityTypes,
        map: (state: CRUD.State): Map<string, any> => state.map,
        foreignKeys: (state: CRUD.State): Map<string, any> => state.fkMap,
        updated: (state: CRUD.State): number => state.updated
    }
}

